import { SelectIdType } from '../@types/kobold';
import { CustomerAddress, MallType, ShippingAddress } from '../generated/graphql';

export const serviceName = 'WarrantUp（ワラントアップ）';
export const pageTitleSuffix = ` | ${serviceName}`;
export const blankColumnValue = '---';
export const rowsPerPageOptions = [100, 200, 500, 1000];
export const telRe = /^(\d{10}|\d{11})$/;

export function isLocal() {
  return process.env.NEXT_PUBLIC_ENV === 'local';
}

export const capitalizeFirst = (str: string): string => {
  if (!str) return '';
  return str[0].toUpperCase() + str.substring(1).toLowerCase();
};

export const truncateStr = (str: string, len: number) => {
  if (str.length <= len) return str;
  return `${str.substr(0, len)}...`;
};

export const convertToHalfwidthNum = (text: string): number => {
  if (text.match(/[0-9]/g)) return parseInt(text, 10);

  const fullwidthRe = /[０-９]/g;
  if (!text.match(fullwidthRe)) return 0;

  const convertedText = text.replace(fullwidthRe, (s) => String.fromCharCode(s.charCodeAt(0) - 65248));
  return parseInt(convertedText, 10);
};

export const get1LineAddress = (address: Omit<ShippingAddress, 'count'> | CustomerAddress | null | undefined) => {
  if (!address) return '---';

  const {
    postalCode, prefecture, municipality, district, building,
  } = address;
  let output = `${prefecture ?? ''}${municipality ?? ''}${district ?? ''} ${building ?? ''}`;

  if (!output.trim()) {
    output = address.addressFull || '---';
  }

  if (postalCode) {
    output = `〒${postalCode.substring(0, 3)}-${postalCode.substring(3)} ${output}`;
  }

  return output;
};

export const camelToSnake = (str: string) => str.replace(/[A-Z]/g, (s) => `_${s.charAt(0).toLowerCase()}`);

export const parseSelectIdType = (str: string): SelectIdType => (str.match(/^\d+$/) ? parseInt(str, 10) : str);

export const convertMallTypeTextToEnum = (type: string): MallType => {
  switch (type) {
    case '楽天':
      return MallType.Rakuten;
    case 'Yahoo':
      return MallType.Yahoo;
    case 'Amazon':
      return MallType.Amazon;
    case '自社':
    default:
      return MallType.Official;
  }
};
