import { Container } from '@mui/material';
import React, { ReactNode } from 'react';

interface WrapperProps {
  children: ReactNode;
}

const Wrapper = (props: WrapperProps) => {
  const { children } = props;

  return (
    <Container
      sx={{
        minWidth: '1080px',
      }}
    >
      {children}
    </Container>
  );
};

export default Wrapper;
