import { Box } from '@mui/material';
import Head from 'next/head';
import React, { useEffect } from 'react';
import Link from 'next/link';
import Wrapper from '../components/organisms/Wrapper';
import usePathChange from '../hooks/usePathChange';
import useShopAuth from '../hooks/useShopAuth';
import { serviceName } from '../lib/utils';

const Top = () => {
  const [isAuthorized] = useShopAuth();
  const { moveTo } = usePathChange();

  useEffect(() => {
    if (isAuthorized === true) {
      moveTo({ page: '/home' });
    }
    if (isAuthorized === false) {
      moveTo({ page: '/signin' });
    }
  }, [isAuthorized, moveTo]);

  return (
    <>
      <Head>
        <title>{serviceName}</title>
      </Head>
      <Wrapper>
        <Box
          sx={{
            display: 'flex',
            height: '100vH',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Link href="/signin">
            <a>
              <Box
                sx={{
                  height: 70,
                  px: 5,
                  py: 1,
                  borderRadius: '4px',
                  bgcolor: 'primary.main',
                }}
              >
                <img
                  src="/img/logo_white.svg"
                  alt="WarrantUp"
                  width={200}
                  height={60}
                />
              </Box>
            </a>
          </Link>
        </Box>
      </Wrapper>
    </>
  );
};

export default Top;
