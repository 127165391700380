import { useRouter } from 'next/router';
import { useCallback, useEffect, useRef } from 'react';
import { Order } from '../components/molecules/EnhancedTableHead';
import { ExternalApiType, useBaseQuery } from '../generated/graphql';

export type PageType =
  | '/signin'
  | '/signup'
  | '/password_reset'
  | '/home'
  | '/plans'
  | '/plans/seeds'
  | '/contracts'
  | '/claims'
  | '/products'
  | '/invoices'
  | '/logs'
  | '/settings/general'
  | '/settings/members'
  | '/settings/design'
  | '/settings/api'
  | '/account/general';

export interface PathChangeType {
  page: PageType;
  displayId?: string;
}

const usePathChange = () => {
  // DO NOT USE router DIRECTLY
  const router = useRouter();
  const routerRef = useRef(router);

  useEffect(() => {
    routerRef.current = router;
  }, [router]);

  const { data, loading } = useBaseQuery();
  const slug = data?.shopAPI?.shop?.slug;

  const moveTo = useCallback(
    (info: PathChangeType) => {
      const { page, displayId } = info;

      // 認証不要のページ
      switch (page) {
        case '/signin':
        case '/signup':
        case '/password_reset': {
          routerRef.current.push(page);
          return;
        }
        default:
          break;
      }

      // 認証が必要な、各詳細ページ
      if (displayId) {
        routerRef.current.push({
          pathname: `/organizations/[slug]${page}/[displayId]`,
          query: { slug, displayId },
        });
        return;
      }

      // 認証が必要な、その他のページ
      routerRef.current.push({
        pathname: `/organizations/[slug]${page}`,
        query: { slug },
      });
    },
    [slug],
  );

  const moveToSettingApi = useCallback(
    (type: ExternalApiType) => {
      if (!slug) return;

      routerRef.current.push({
        pathname: '/organizations/[slug]/settings/api',
        query: { slug, type },
      });
    },
    [slug],
  );

  const applyPagination = useCallback(
    (offset: number, limit: number, orderBy: string, orderType: Order) => {
      if (!slug) return;
      const newQuery = {
        ...routerRef.current.query,
        slug,
        offset: String(offset),
        limit: String(limit),
        order_by: orderBy,
        order_type: orderType,
      };

      routerRef.current.push({
        pathname: routerRef.current.pathname,
        query: newQuery,
      });
    },
    [slug],
  );

  return {
    isRouterLoading: loading,
    moveTo,
    moveToSettingApi,
    applyPagination,
  };
};

export default usePathChange;
