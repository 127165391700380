import { useState, useEffect } from 'react';
import { useBaseQuery } from '../generated/graphql';

const useShopAuth = () => {
  const { data, loading, error } = useBaseQuery();
  const [isAuthorized, setIsAuthorized] = useState<boolean | undefined>(
    undefined,
  );

  useEffect(() => {
    if (loading) return;

    setIsAuthorized(data !== undefined);
  }, [data, loading]);

  return [isAuthorized, loading, error] as const;
};

export default useShopAuth;
